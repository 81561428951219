import Vue from 'vue'
import VueRouter from 'vue-router'
import NDA from '../views/mycelium/NDA.vue'
import E404 from '../views/E404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/mycelium/nda',
    name: 'NDA',
    component: NDA
  },
  {
    path: '*',
    component: E404
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
