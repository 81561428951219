<template>
  <div class="text-center ma-2">
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
    >
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
  export default {
    props: [
      'snackbar',
      'text'
    ],
  }
</script>