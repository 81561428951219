<template>
  <v-app>
    <v-app-bar app color="primary" dark :src="require('@/assets/toolbar.png')">
      <div class="d-flex align-center">
        <v-img alt="Vuetify Logo" class="shrink mr-2" contain :src="require('@/assets/primary.png')"
          transition="scale-transition" width="120" />
      </div>

    </v-app-bar>
       <v-main>
      <NDA></NDA>
    </v-main>
  </v-app>
</template>

<script>
  // @ is an alias to /src
  import NDA from '@/components/AcceptanceForm.vue'

  export default {
    name: 'Home',
    components: {
      NDA
    }
  }
</script>