<template>
  <div style="text-align: left;">
    <p>This Agreement is made on: {{ new Date().toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric"}) }}</p>
    <p>Parties</p>
    <p>1 {{ companyName }} (ACN {{ acn }}) of Floor 1, 172 Hume Street, TOOWOOMBA QLD 4350 (the Discloser).</p>
    <p>2 The Recipient:</p>
    <p>Name: {{ name }}</p>
    <p>Corporate Identifier (if applicable): {{ company }}</p>
    <p>Address: {{ address }}</p>
    <p>Recitals</p>
    <p>A The Discloser may provide Confidential Information to the Recipient in connection with commercial discussions (the
    Discussions).</p>
    <p>B In consideration of the Discloser engaging with the Recipient in relation to the Discussions, the Recipient agrees
    to treat the Confidential Information as confidential on the terms and conditions set out below.
    It is agreed as follows.</p>
    <p>1 Confidential Information means this Agreement and all information of the Discloser or its related bodies corporate
    which is disclosed to or otherwise comes to be known by the Recipient, whether before or after the date of this
    Agreement, which is in fact or which is reasonably regarded by the Discloser as confidential to the Discloser. This
    includes but is not limited to information relating to technology, processes, products, specifications, inventions
    or designs used or developed by the Discloser, trade secrets and know-how and information of a commercially
    sensitive nature.</p>
    <p>Permitted Purpose means the purpose of enabling the Recipient and the Discloser to have the Discussions.</p>
    <p>2 The Recipient must:</p>
    <p>(a) keep confidential all Confidential Information and, except as permitted under this Agreement, not use or
    disclose any of it without the Discloser’s prior written consent; 
    <p>(b) ensure that the Confidential Information is only disclosed to those directors, employees and professional
    advisers of it (and its related bodies corporate) who have a specific need to access the Confidential Information
    for the Permitted Purpose;</p>
    <p>(c) ensure that those directors, employees and professional advisers of it (and its related bodies corporate) to
    whom the Confidential Information is disclosed comply with this Agreement; and</p>
    <p>(d) immediately on request by the Discloser, return or destroy all copies of the Confidential Information.</p>
    <p>3 The obligations in this Agreement do not apply to any Confidential Information which the Recipient can prove:</p>
    <p>(a) is in, or comes into, the public domain other than by a breach of this Agreement;</p>
    <p>(b) was lawfully in its possession prior to disclosure by the Discloser;</p>
    <p>(c) was received from a third party who is not under an obligation to the Discloser to maintain the Confidential</p>
    <p>Information in confidence and who legitimately obtained the Confidential Information; or</p>
    <p>(d) subject to clause 4, it is required to disclose in order to enforce this Agreement or under law or a binding order of a governmental agency or court.</p>
    <p>4 If the Recipient wishes to rely on clause 3(d), it must:</p>
    <p>(a) inform the Discloser of the required disclosure in advance to enable the Discloser to have a reasonable opportunity to object to the required disclosure; and</p>
    <p>(b) use all reasonable efforts to obtain confidential treatment of such Confidential Information required to be disclosed.</p>
    <p>5 The Recipient acknowledges that:</p>
    <p>(a) the Discloser owns the Confidential Information and all rights (including intellectual property rights) in it;</p>
    <p>and</p>
    <p>(b) the Discloser reserves all rights in the Confidential Information. No rights in the Confidential Information,
    other than those expressly contained in this Agreement, are granted or to be implied from this Agreement. In
    particular, no licence is granted directly or indirectly under any patent, invention, discovery, copyright or other
    intellectual property right now or in the future held, made, obtained or licensable by the Discloser.</p>
    <p>6 The Recipient further acknowledges that:</p>
    <p>(a) the value of the Confidential Information to the Discloser is unique and difficult to assess in monetary terms;</p>
    <p>(b) a breach by the Recipient of any of its obligations under this Agreement would irreparably harm the Discloser
    and damages would not be an adequate remedy for any such breach; and</p>
    <p>(c) if the Recipient actually breaches or threatens to breach this Agreement, the Discloser will be entitled to
    enforce this Agreement by injunctive relief or specific performance as a remedy (in addition to other available
    relief) without proof of actual or special damage.</p>
    <p>7 This Agreement contains the entire agreement and understanding between the parties with respect to its subject
    matter and supersedes all prior agreements and understandings between the parties in connection with it.</p>
    <p>8 This Agreement may be amended only by another agreement executed by both parties.</p>
    <p>9 The obligations in this Agreement will continue indefinitely in respect of each item of Confidential Information
    until such Confidential Information subsequently falls within an exception in clause 3.</p>
    <p>10 A party may not assign any of its rights or obligations under this Agreement without the written consent of the
    other party.</p>
    <p>11 This Agreement is governed by the laws of Queensland. Each party irrevocably and unconditionally submits to the
    non-exclusive jurisdiction of courts exercising jurisdiction there.</p>
    <p>12 This Agreement may be executed in any number of counterparts. All counterparts together will be taken to
    constitute one instrument.</p>
    <div>{{ ndaHTML }}</div>
  </div>
</template>

<script>
export default {
  props: [
    'name',
    'address',
    'email',
    'company'
  ],
  data() {
    return {
      companyName: 'Mycelium Ventures Pty Ltd (and its related bodies corporate)',
      acn: '75 630 576 635'
    }
  }
}
</script>